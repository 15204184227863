import { useEffect, useCallback } from "react";
import { useRouter } from "next/router";
import cookie from "cookie";
import { isBrowser } from "../utils";
import { useStore } from "../../store";
import { CookieConsentStatus } from "../../store/index.d";

export const GTM_ID = process.env.NEXT_PUBLIC_GTM_KEY;
export const GTM_AUTH = process.env.NEXT_PUBLIC_GTM_AUTH;
export const GTM_PREVIEW = process.env.NEXT_PUBLIC_GTM_PREVIEW;

const COOKIE_CONSENT_DOMAIN = process.env.NEXT_PUBLIC_COOKIE_CONSENT_DOMAIN;

export const pageview = () => {
  window.dataLayer.push({
    event: "pageview",
    page: window.location.pathname,
  });
};

export const pushToDataLayer = (payload: object) => {
  window.dataLayer.push(payload);
};

type SubscriptionSuccessEvent = {
  type: string;
  amount: number;
  currency: string;
  userId: string;
  referralSource?: string;
};

export const pushAddToMailingList = () => {
  window.dataLayer.push({
    event: "addToMailingList",
  });
};

export const pushLeadGenSuccess = () => {
  window.dataLayer.push({
    event: "leadGenFormSubmissionSuccess",
  });
};

const formatEcommerceData = (payload: EcommerceEvent) => ({
  ecommerce: {
    purchase: {
      actionField: payload,
      products: payload.items,
    },
  },
});

export const pushImpactShopSuccess = (shopPayload: EcommerceEvent) => {
  window.dataLayer.push({
    event: "impactShopPurchase",
    ...formatEcommerceData(shopPayload),
  });
};

// Both the custom subscription success and ecommerce event payloads
// must be sent in the same push
export const pushSubscriptionSuccess = (
  subscriptionPayload: SubscriptionSuccessEvent,
  ecommercePayload: EcommerceEvent
) => {
  window.dataLayer.push({
    event: "subscriptionSuccess",
    ...subscriptionPayload,
    ...formatEcommerceData(ecommercePayload),
  });
};

type EcommerceEvent = {
  id: string;
  revenue: number;
  currency: string;
  items: object[];
};

export function useTrackPageViews() {
  const router = useRouter();

  useEffect(() => {
    // Track a page view event on initial load
    if (getEcologiConsentCookie()) {
      pageview();
    }

    // Track a page view event on every page change
    // and remove on unmount
    router.events.on("routeChangeComplete", pageview);
    return () => {
      router.events.off("routeChangeComplete", pageview);
    };
  }, [router.events]);
}

const ONE_YEAR_SECONDS = 60 * 60 * 24 * 365;
/**
 * Note, this value must be kept in-sync with the cookie consent name defined
 * in...
 * The Google Tag Manager (GTM) Consent settings
 * The Framer cookie banner implementation
 * Zero: https://github.com/ecologi/mono/blob/main/apps/zero-client/src/config/index.ts#L11
 */
const COOKIE_NAME = "ecologi_cookie_consent_20220224";

const setEcologiConsentCookie = (value: string) => {
  document.cookie = cookie.serialize(COOKIE_NAME, value, {
    maxAge: ONE_YEAR_SECONDS,
    domain: COOKIE_CONSENT_DOMAIN,
  });
};

const getEcologiConsentCookie = () => {
  const cookies = cookie.parse(document.cookie);
  return cookies[COOKIE_NAME];
};

/**
 * TODO: Move CookieConsentProps type to shared package
 *
 * import { CookieConsentProps } from "@ecologi/react-components";
 */
export function useCookieConsent(): any {
  const { cookieConsentStatus, setCookieConsent } = useStore();

  useEffect(() => {
    if (!isBrowser()) return;

    if (!getEcologiConsentCookie()) {
      setCookieConsent(CookieConsentStatus.CONSENT_REQUESTED);
    }
  }, [setCookieConsent]);

  const onReject = useCallback(() => {
    setEcologiConsentCookie("false");
    setCookieConsent(CookieConsentStatus.REJECTED);
  }, [setCookieConsent]);

  const onAccept = useCallback(() => {
    setEcologiConsentCookie("true");
    setCookieConsent(CookieConsentStatus.ACCEPTED);
    pageview();
  }, [setCookieConsent]);

  return {
    onReject,
    onAccept,
    isOpen: cookieConsentStatus === CookieConsentStatus.CONSENT_REQUESTED,
  };
}
