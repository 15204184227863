import { ITokenCache } from "@ecologi/nextjs-auth0/src/tokens/token-cache";
import fetch from "cross-fetch";
import { selectPaymentErrorMessage } from "../selectors";
import { buildApiUrl, isBrowser } from "../utils";
import { APIError, APIErrorBody, defaultError } from "./api-error";

type FetchFromAPIOptions = {
  file?: boolean;
  // proxy: ecologi.com/api/proxy/:path -> call API through an lambda@edge
  // edge:  ecologi.com/api/:path       -> call a NextJS lambda@edge
  // api:   api.ecologi.com/:path       -> call the API directly
  passthrough?: "proxy" | "edge" | "api";
  raw?: boolean;
};

const clientBase = process.env.NEXT_PUBLIC_CLIENT_URL || "";

const getRequestUrl = (
  options: Omit<FetchFromAPIOptions, "file">,
  path: string
) => {
  // Serverless fns always call directly endpoints
  if (!isBrowser()) return buildApiUrl(path);

  // In the browser...
  switch (options.passthrough) {
    case "api":
      return buildApiUrl(path);
    case "proxy":
      return `${clientBase}/api/proxy${path}`;
    case "edge":
      return `${clientBase}/api${path}`;
  }
};

export async function fetchFromAPI<T = any>(
  path: RequestInfo,
  fetchFromAPIOptions?: RequestInit & FetchFromAPIOptions,
  tokenCache?: ITokenCache
): Promise<T> {
  const {
    passthrough = "proxy",
    raw = false,
    file = false,
    ...options
  } = fetchFromAPIOptions || {};

  let opts: RequestInit = {
    ...options,
    headers: {
      ...(file ? {} : { "content-type": "application/json" }),
      ...options.headers,
      ...(!isBrowser() && {
        "User-Agent": "ecologi-web-client-ssr/1.0",
      }),
    },
  };

  if (tokenCache) {
    const { accessToken } = await tokenCache.getAccessToken();

    opts = {
      ...options,
      headers: {
        ...options.headers,
        ...(!isBrowser() && {
          "User-Agent": "ecologi-web-client-ssr/1.0",
        }),
        Authorization: `Bearer ${accessToken}`,
      },
    };
  }

  const url = getRequestUrl({ passthrough }, path.toString());
  const response = await fetch(url, opts);

  if (!response) throw new APIError(502, defaultError);

  type Body = (
    | T
    | {
        data?: T;
      }
    | {
        errors?: Array<{ messages: [] }>;
        error?: any;
      }
  ) & {
    code?: string;
  };
  type ErrorBody = Exclude<Body, { data?: T } | T>;

  if (raw) {
    return response as T;
  }

  const body: Body | null = await response
    .json()
    .catch((e) => (console.warn("Response de-serialisation error", e), null));

  const deserialiseErrorResponse = (body: ErrorBody | null): APIErrorBody => {
    if (!body) return defaultError;
    return Array.isArray(body?.errors) || body?.error ? body : defaultError;
  };

  switch (response.status) {
    case 200:
    case 201:
    case 202:
    case 204: {
      return "data" in body ? body.data : (body as T);
    }

    case 400:
    case 401:
    case 403:
    case 404:
    case 406:
    case 413:
    case 409: {
      console.error(body);
      const error = deserialiseErrorResponse(body as ErrorBody);
      throw new APIError(response.status, error);
    }

    case 500:
    case 501:
    case 502:
    case 503:
    case 504: {
      console.error(body);
      const error = deserialiseErrorResponse(body as ErrorBody);
      throw new APIError(response.status, error);
    }

    default: {
      console.warn("Unhandled response status", body);
    }
  }
}
